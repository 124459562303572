import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
  formControl: {
    minWidth: 300,
  },
}));
